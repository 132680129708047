<h3><fa-icon [icon]="faMoneyBillTrendUp"></fa-icon> Payout Detail</h3>

<app-commission-statistics-quick-filter
  *ngIf="
    filterData && (!hiddenKeyMap['agent-id'] || !hiddenKeyMap['agency-id'])
  "
  [data]="{ data: filterData }"
  [filter]="filter"
  [hiddenKeyMap]="hiddenKeyMap"
  (filterChanged)="
    filterChanged($event)
  "></app-commission-statistics-quick-filter>

<app-commission-statistics-filter
  *ngIf="filterData"
  [data]="{ data: filterData }"
  [filter]="filter"
  [hiddenKeyMap]="hiddenKeyMap"
  [loadingChanged]="loadingChanged"
  (filterChanged)="filterChanged($event)"></app-commission-statistics-filter>

<app-loading *ngIf="!filterData || loading"></app-loading>

<!-- <div class="flex flex-row w-full gap-2" *ngIf="filterData">
  <div class="grow">
    <app-commission-statistics-filter
      [data]="{ data: filterData }"
      [filter]="filter"
      [hiddenKeyMap]="hiddenKeyMap"
      (filterChanged)="
        filterChanged($event)
      "></app-commission-statistics-filter>
  </div>
</div> -->
<!-- 
<div class="join w-full mb-4" *ngIf="filterData">
  <input
    #newItem
    class="input input-bordered join-item grow"
    placeholder="Search Consumer"
    type="text"
    [(ngModel)]="filter['consumer-name']"
    (keydown.enter)="$event.preventDefault(); filterChanged()" />
  <button
    type="button"
    class="btn btn-primary join-item"
    (click)="filterChanged()">
    Search
  </button>
</div> -->

@if (filter['user-id'] && filter['user-id'][0]) {
  <app-payouts-table
    [data$]="commissions$"
    [filter]="filter"
    [userId]="filter['user-id'][0]"
    (filterChanged)="filterChanged($event)">
  </app-payouts-table>
}

<div infiniteScroll [fromRoot]="true" (scrolled)="onScroll()"></div>

<app-loading *ngIf="loadingNext"></app-loading>
