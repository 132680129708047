import { NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faMoneyBillTrendUp } from '@fortawesome/pro-regular-svg-icons';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ReplaySubject, takeWhile } from 'rxjs';
import { CommissionStatisticsFilterComponent } from 'src/app/shared/components/commission-statistics/commission-statistics-filter/commission-statistics-filter.component';
import { CommissionStatisticsQuickFilterComponent } from 'src/app/shared/components/commission-statistics/commission-statistics-quick-filter/commission-statistics-quick-filter.component';
import { keys } from 'src/app/shared/components/commission-statistics/keys';
import { PayoutStatisticsService } from 'src/app/shared/components/commission-statistics/payout-statistics.service';
import { PayoutsTableComponent } from 'src/app/shared/components/payouts/payouts-table/payouts-table.component';
import { LoadingComponent } from 'src/app/shared/components/ui-components/loading/loading.component';
import { Commission, UserType } from 'src/app/shared/models';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserCacheService } from 'src/app/shared/services/user-cache.service';
import { getWindow } from 'ssr-window';

@Component({
  selector: 'app-payouts-overview',
  standalone: true,
  imports: [
    CommissionStatisticsFilterComponent,
    CommissionStatisticsQuickFilterComponent,
    FaIconComponent,
    FormsModule,
    InfiniteScrollModule,
    LoadingComponent,
    PayoutsTableComponent,
    NgIf,
    NgFor,
  ],
  templateUrl: './payouts-overview.component.html',
  styleUrl: './payouts-overview.component.scss',
})
export class PayoutsOverviewComponent {
  faMoneyBillTrendUp = faMoneyBillTrendUp;

  @Input() hiddenKeys: string[] = [];
  hiddenKeyMap: { [key: string]: boolean } = {};

  @Input() hiddenTables: string[] = [];
  hiddenTableMap: { [key: string]: boolean } = {};

  lastSort?: any;
  loading = true;
  loadingChanged = new EventEmitter();
  loadingNext = false;
  filter: any = {
    order: { field: 'commissionAmount', direction: 'desc' },
  };

  @Input() filterUserId?: string;
  @Input() identifierGroupId?: string;
  @Input() policyId?: string;

  private allCommissions?: Commission[];
  allCycles?: any;
  currentCycleSet = false;

  commissions$: ReplaySubject<any> = new ReplaySubject<any>(1);

  filterData?: any;

  constructor(
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private payoutStatisticsService: PayoutStatisticsService,
    private userCacheService: UserCacheService
  ) {}

  ngOnInit(): void {
    console.log('init payouts overview', {
      filterUserId: this.filterUserId,
      identifierGroupId: this.identifierGroupId,
      policyId: this.policyId,
    });

    if (this.filterUserId) {
      this.filter['user-id'] = [{ id: this.filterUserId }];
    }

    if (this.identifierGroupId) {
      this.filter['identifier-groupId'] = [{ id: this.identifierGroupId }];
      this.currentCycleSet = true;
    }

    if (this.policyId) {
      this.filter['policy-id'] = [{ id: this.policyId }];
      this.currentCycleSet = true;
    }

    if (!this.currentCycleSet) {
      this.setCurrentCycle().then(() => {
        this.runSearch();
      });
    } else {
      this.runSearch();
    }

    for (const k of this.hiddenKeys) {
      this.hiddenKeyMap[k] = true;
    }
    for (const k of this.hiddenTables) {
      this.hiddenTableMap[k] = true;
    }
    // this.runSearch();

    for (const k of keys) {
      this.filter[k.name] = [];
    }
  }

  runSearch = (forceScroll = false, loadNext = false): Promise<void> =>
    new Promise(resolve => {
      console.log('runSearch', {
        currentCycleSet: this.currentCycleSet,
        filter: this.filter,
        forceScroll,
        loading: this.loading,
        loadNext,
      });

      if (!this.currentCycleSet) {
        return;
      }
      console.log('start search');
      this.loading = true;
      this.loadingChanged.emit(true);

      this.authService.currentUser$
        .pipe(takeWhile(u => !u.userType, true))
        .subscribe(user => {
          console.log('search user ', user);
          if (
            // user.userType === UserType.AGENCY &&
            !this.filter['user-id'] ||
            this.filter['user-id'].length === 0
          ) {
            this.filter['user-id'] = [{ id: user.id }];
          }
          if (!this.filter['agency-filter-type']) {
            this.filter['agency-filter-type'] = 'MY_PAYOUTS';
          }

          if (user.userType === UserType.AGENCY) {
            // if (!this.filter['agency-id']) {
            //   this.filter['agency-id'] = [];
            // }
            // this.filter['agency-id'].push(user);
          } else if (user.userType === UserType.AGENT) {
            this.hiddenKeyMap['agency-id'] = true;
            this.hiddenTableMap['agency-id'] = true;
            this.hiddenKeyMap['agent-id'] = true;
            this.hiddenTableMap['agent-id'] = true;

            if (!this.filter['agent-id']) {
              this.filter['agent-id'] = [];
            }
            this.filter['agent-id'].push(user);
          }

          console.log('run inner');
          this.payoutStatisticsService
            .loadAll(this.filter, 100, loadNext ? this.lastSort : undefined)
            .then(res => {
              console.log('search res', res);

              if (res?.lastSort) {
                this.lastSort = res?.lastSort;
              }

              const payouts = res?.source?.map((payout: unknown) =>
                Commission.fromJSON(payout)
              );

              if (payouts) {
                if (loadNext) {
                  this.allCommissions = this.allCommissions?.concat(payouts);
                  if (this.allCommissions) {
                    this.commissions$.next({
                      loading: false,
                      payouts: this.allCommissions,
                      payout: null,
                      error: null,
                    });
                  }
                } else {
                  this.allCommissions = payouts;
                  this.commissions$.next({
                    loading: false,
                    payouts: payouts,
                    payout: null,
                    error: null,
                  });
                }

                for (let payout of payouts) {
                  this.userCacheService.addToCache(
                    payout.agent.id,
                    payout.agent
                  );
                }
              }

              if (!this.filterData) {
                if (res.totalPayouts > 0) {
                  this.filterData = res.data;
                } else {
                  this.filterData = {};
                }

                if (this.allCycles) {
                  this.filterData['cycle-name'] = this.allCycles;
                }
              }

              this.loading = false;
              this.loadingChanged.emit(false);
              this.cdr.detectChanges();

              if (forceScroll) {
                this.scrollTop();
              }

              resolve();
            });
        });
    });

  scrollTop() {
    const window = getWindow();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  filterChanged(data?: any) {
    this.loading = true;
    this.runSearch(data?.forceScroll);
  }

  onScroll() {
    if (!this.loadingNext) {
      this.loadingNext = true;
      this.runSearch(false, true).then(() => (this.loadingNext = false));
    }
  }

  setCurrentCycle = () =>
    new Promise<void>(resolve => {
      this.payoutStatisticsService.getCurrentCycle(this.filter).then(res => {
        console.log('res cycle', res);
        this.allCycles = res?.cycles ?? [];

        const cycle = this.allCycles?.find(
          (x: any) => x.name === res.cycle?.name
        );
        if (cycle) {
          this.filter['cycle-name'] = [cycle];
        }

        this.currentCycleSet = true;
        resolve();
      });
    });
}
